import React from "react";
import reactCreateClass from "create-react-class";
import RaisedButton from "material-ui/RaisedButton";
import * as Immutable from "immutable";

import Err from "js/components/error";
import {Success} from "js/components/notification";
import * as fetch from "js/fetch";
import {ClientsContext, SelectedClientIdContext} from "js/data/contexts";

const DeleteAssetsForm = reactCreateClass({

  getInitialState() {
    return {
      successMessage: null,
      dirsToCheck: Immutable.Set()
    };
  },

  render() {
    return <div>{this.props.selectedClientId && this.renderForm()}</div>;
  },

  renderForm() {
    const {idToClient, selectedClientId} = this.props;
    const {dirsToCheck, errorMessage} = this.state;
    const client = idToClient.get(selectedClientId);
    const clientType = client.get("type");
    const canBeDeleted = clientType === "CRM_DATA_DELETED" || clientType === "DEAD";
    return (
        <div>
          {!canBeDeleted &&
          <Err
              type="error"
              message="Client must be 'CRM_DATA_DELETED' or 'DEAD'"
              customStyle={{margin: "0.5rem"}} />}

          <RaisedButton
              label={"Delete assets"}
              icon={<i className={"fa fa-trash"} />}
              backgroundColor={"red"}
              disabled={!canBeDeleted}
              onClick={() => {
                if (this.confirmDelete(client.get("internal_name"))) {
                  this.deleteAssets(selectedClientId);
                }
              }}
              style={{margin: "0.5rem"}} />

          {errorMessage &&
          <Err
              type="error"
              message={errorMessage}
              customStyle={{margin: "0.5rem"}} />}
          {!errorMessage && dirsToCheck.size > 0 &&
          <Err
              type="warning"
              message={generateOldFilesWarning(dirsToCheck, client.get("name"))}
              customStyle={{margin: "0.5rem"}} />}
          <Success
              message={this.state.successMessage}
              onRequestClose={() => this.setState({successMessage: null})} />
        </div>
    );
  },

  confirmDelete(clientName) {
    return window.confirm(`Are you sure you want to delete all assets for '${clientName}'`);
  },

  deleteAssets(clientId) {
    fetch.getJson("clients/delete-files", {"cube19-client-id": clientId})
        .then(response => {
          const deletedFileCount = Object.values(response).reduce((total, files) => total + files.length, 0);
          this.setState({
            successMessage: deletedFileCount + " file(s) deleted",
            errorMessage: null
          });
        }, error => {
          const errorStatus = error.response.status;
          if (errorStatus === 400) {
            error.response
                .json()
                .then(errorJson => this.setState({errorMessage: errorJson.message}));
          } else {
            this.setState({errorMessage: "Failed to delete client assets."});
          }
        });

    fetch.getJson("clients/old-files")
        .then(bucketsAndFiles => this.setState({dirsToCheck: filePathsToUniqueDirs(bucketsAndFiles)}));
  }

});

const filePathsToUniqueDirs = (bucketsAndFiles) => {
  const dirPaths = bucketsAndFiles
      .map(bucketAndFiles => {
        return bucketAndFiles.files.map(file => bucketAndFiles.bucketName + "/" + file);
      })
      .flat()
      .map(filePath => {
        let filePathSegments = filePath.split("/");
        filePathSegments.pop();
        return filePathSegments.join("/") + "/";
      });
  return Immutable.Set(dirPaths);
};

const generateOldFilesWarning = (dirsToCheck, clientName) => {
  const nonMatchingDirs = dirsToCheck.filter(dir => !dir.includes(clientName));
  const matchingDirs = dirsToCheck.filter(dir => dir.includes(clientName));
  let message = "Directories that match the client in old save locations:";
  message += matchingDirs.reduce((acc, dir) => acc + "\n" + dir, "");
  message += "\n\nRemaining directories in old save locations:";
  message += nonMatchingDirs.reduce((acc, dir) => acc + "\n" + dir, "");
  return message;
};

const ConnectedForm = props => {
  const {selectedClientId} = React.useContext(SelectedClientIdContext);
  const {idToClient} = React.useContext(ClientsContext);
  return <DeleteAssetsForm {...props} selectedClientId={selectedClientId} idToClient={idToClient} />;
};

export default ConnectedForm;
